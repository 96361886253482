.square {
  margin: 0;
  position: absolute;
}

.bullet{
	height:10px;
	background-color:black;
	margin:3px;
	position: absolute;
}

.player{
	background-color:Gray;
}


.enemy{
	background-color:Red;
}

.main-frame{
	border:solid;
	border-color:black;
	border-width:2px;
	margin-left:20px;
	margin-top:20px;
	position:relative
}

.splash-screen{
	background-color:lightgrey;
	padding:10px;
	margin:45px;
	position:absolute;
}