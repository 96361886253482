.personal-project-card{
    cursor:pointer;
    width:250px;
    height:200px;
    position:relative;

    img{
        position:absolute;
        left:0;
        top:0;
        z-index: 1;
        object-fit: cover;

    }
}

.card-foreground{
    z-index:2;
    color:black;
    background-color: black;
    position:absolute;
    left:0;
    top:0;
    width:250px;
    height:200px;
    opacity:50%;
}

.card-foreground-text{
    z-index:3;
    color:white;
    margin:15px;
}

.offwhite-background{
    background-color:#e0f2f1;
}

.resume-card{
    margin-top:10px;
    margin-bottom:10px;
}

.intro-projects-card{
    margin-top:10px;
    margin-bottom:10px;
}

.flex-1{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
}

.flex{
    display:flex;
}

.flex-col{
    flex-direction: column;
}

.flex-center{
    align-items:center;
    justify-content:center;
}

.h-full{
    height:100%;
}

.w-full{
    width:100%;
}




@media (min-width:0px){
    .intro-projects-card{
        width:95%;
    }

    .resume-card{
        width:95%;
    }

    
    .personal-project-card{
        width:150px;
        height:250px;
        
    }
    .card-foreground{
        width:150px;
        height:250px;
    }
}




@media (min-width:720px){
    .intro-projects-card{
        width:75%;
    }

    .resume-card{
        width:85%;
    }

    .personal-project-card{
        width:250px;
        height:200px;

    }
    .card-foreground{
        width:250px;
        height:200px;

    }
}




@media (min-width:1080px){
    .intro-projects-card{
        width:50%;
    }

    .resume-card{
        width:75%;
    }
}